import React from "react";
import LazyLoad from "react-lazy-load";
const HomeDesktop=({backGroundColor,image, message1, message2})=>{

    return(
        <>
            <div style={{minHeight: "100vh", backgroundColor:backGroundColor}}>
                <div style={{display:"flex"}}>
                    <div style={{color:"white", paddingTop:"11px", paddingLeft:"20px", fontSize:"20px"}}>
                        ONLY MANOJ KUMAR
                    </div>
                    <div>
                        <div style={{color:"white",marginTop:"20px", marginLeft:"10px", width:"140vh",
                            height: "2px",
                            background: "linear-gradient(267deg, #00F0FF 4.01%, #5200FF 57.55%, #FF2DF7 114.97%)"}}>
                        </div>
                    </div>

                </div>
                <div style={{marginTop:"6%"}}>
                    <div style={{width: "400px",
                        height: "auto",
                        margin: "0 auto",
                        position: "relative"}}>
                        <LazyLoad width={400} height={400}>
                    <img src={image} style={{width:"400px", height:"400px",display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"}} />
                        </LazyLoad>
                    </div>
                </div>
                <div style={{paddingLeft:"10px", paddingRight:"10px",
                    textAlign:"center", fontSize:"28px",fontFamily:"K2D",
                    textTransform:"uppercase"}}>
                    <div style={{marginTop:"2%", color:"white"}}>
                        {message1}
                    </div>
                    <div style={{color:"white"}}>
                        {message2}
                    </div>
                </div>

                <div style={{alignItems:"bottom", textAlign:"center",
                    paddingBottom:"15px",
                    position:"absolute",
                    bottom:0,
                    width: "100%"}}>
                    <div style={{color:"white", fontSize:"20px",fontFamily:"K2D"}}>
                        GET IN TOUCH
                    </div>
                    <div style={{display:"flex", textAlign:"center", paddingTop:"20px", paddingBottom:"25px",
                        flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <div style={{paddingRight:"40px"}}>
                            <a href="mailto:vsrmanoj.99@gmail.com" target="/"><img src="/images/mail.png"/></a>
                        </div>
                        <div style={{paddingRight:"40px"}}>
                            <a href="https://linkedin.com/in/manoj-kumarv" target="/"><img src="/images/linkedin.png"/></a>
                        </div>
                        <div style={{paddingRight:"40px"}}>
                            <a href="https://www.instagram.com/_manoj_._kumar_/" target="/"><img src="/images/instagram.png"/></a>
                        </div>
                        <div>
                            <a href="https://github.com/shadowmanoj" target="/"><img src="/images/github.png"/></a>
                        </div>
                    </div>
                    <div style={{color:"white",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,fontFamily:"K2D"}}>
                        Copyright © 2024, Manoj kumar. All rights reserved.
                    </div>
                </div>
            </div>

        </>
    )
}
export default HomeDesktop