import './App.css';
import HomeMobile from "./components/HomeMobile";
import HomeDesktop from "./components/HomeDesktop";
import {useEffect, useMemo, useState} from "react";
import LoaderDesktop from "./components/LoaderDesktop";
import LoaderMobile from "./components/LoaderMobile";

function App() {

  const colors = ["#021F3D", "#02091C", "#000", "#000","#01151C","#000","#010012","#00001C"];
  const colors_2024 = ["#010021", "#02091C", "#2F7671", "#89A2AC","#000","#1B335A","#000","#010215"];
  const images = ["/images/coming_soon_1.jpeg",
                  "/images/coming_soon_2.jpeg",
                  "/images/coming_soon_3.jpeg",
                  "/images/coming_soon_4.jpeg",
                  "/images/coming_soon_5.jpeg",
                    "/images/coming_soon_6.jpeg",
                    "/images/coming_soon_7.jpeg",
                    "/images/coming_soon_8.jpeg"];
    const images_2024 = ["/images/2024/2024_1.png",
        "/images/2024/2024_2.png",
        "/images/2024/2024_3.png",
        "/images/2024/2024_4.png",
        "/images/2024/2024_5.png",
        "/images/2024/2024_6.png",
        "/images/2024/2024_7.png",
        "/images/2024/2024_8.png"];
  const messages_1 = ["Our site is under construction, but your arrival is eagerly anticipated",
                      "We're in the workshop crafting something exciting.",
                      "We'll be up and running in no time.",
                      "Pixels in progress, elegance in motion",
                      "We're in the early stages of lift off",
                      "We're under construction, but the blueprint promises creativity",
                      "We're in the workshop crafting something exciting.",
                      "Code is still warming up",];
  const messages_2 = ["Stay tuned",
                      "Watch this space",
                      "Get Ready for countdown",
                      "Keep this space bookmarked!",
                      "Charge up and stay tuned!",
                      "Stay tuned!",
                      "Watch this space",
                      "Await the unveiling",];

  function getRandomInt() {
    return Math.floor(Math.random() * 8);
  }


  function isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];


    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

    const random = useMemo(() => getRandomInt(), []);

    const [screenLoading, setScreenLoading] = useState(true);
    const [contentReady, setContentReady] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(0);

    function handleImageLoad() {
        setImagesLoaded((prev) => prev + 1);
    }

    const imagesToPreload = images.map((image, index) => (
        <img key={index} src={image} alt={`Preload ${index}`} onLoad={handleImageLoad} style={{ display: 'none' }} />
    ));

    useEffect(() => {
        setTimeout(() => {
            setContentReady(true);
            setScreenLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            {screenLoading ? (
                isMobile() ? <LoaderMobile backgroundColor={colors[random]}/> :
                    <LoaderDesktop backgroundColor={colors[random]}/>
            ) : contentReady && imagesLoaded === images.length ? (
                isMobile() ? (
                    <HomeMobile
                        backGroundColor={colors[random]}
                        image={images[random]}
                        message1={messages_1[random]}
                        message2={[messages_2[random]]}
                    />
                ) : (
                    <HomeDesktop
                        backGroundColor={colors[random]}
                        image={images[random]}
                        message1={messages_1[random]}
                        message2={[messages_2[random]]}
                    />
                )
            ) : null}
            {imagesToPreload}
        </>
    );
}

export default App;
