import React from "react";
import {Dna} from "react-loader-spinner";
const LoaderDesktop=({backgroundColor})=>{
    return(
        <>
            <div style={{minHeight: "100vh", position:"relative",minWidth:"100vw", backgroundColor:backgroundColor,justifyContent: "center"}}>
                <div>
                    <div style={{display:"flex"}}>
                        <div style={{color:"white", paddingTop:"11px", paddingLeft:"20px", fontSize:"20px"}}>
                            ONLY MANOJ KUMAR
                        </div>
                        <div>
                            <div style={{color:"white",marginTop:"20px", marginLeft:"10px", width:"140vh",
                                height: "2px",
                                background: "linear-gradient(267deg, #00F0FF 4.01%, #5200FF 57.55%, #FF2DF7 114.97%)"}}>
                            </div>
                        </div>

                    </div>
                </div>
                <div style={{
                    margin: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    msTransform: "translate(-50%, -50%)",
                    transform: "translate(-50%, -50%)"}}>
                    <Dna
                        visible={true}
                        height="100"
                        width="100"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />
                </div>
            </div>
        </>
    )
}
export default LoaderDesktop