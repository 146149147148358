import React from "react";
import LazyLoad from "react-lazy-load";
const HomeMobile=({backGroundColor, image, message1, message2})=>{

    return(
        <>
            <div style={{minHeight: "100vh", minWidth:"100vw", backgroundColor:backGroundColor}}>
                <div style={{display:"flex"}}>
                    <div style={{color:"white", paddingTop:"9px", paddingLeft:"14px", fontFamily:"K2D"}}>
                        ONLY MANOJ KUMAR
                    </div>
                    <div style={{width:"50%"}}>
                        <div style={{color:"white", marginTop:"20px", marginLeft:"10px",
                            height: "1px",
                            background: "linear-gradient(267deg, #00F0FF 4.01%, #5200FF 57.55%, #FF2DF7 114.97%)"}}>
                        </div>
                    </div>

                </div>
                <div style={{marginTop:"20%",}}>
                    <div style={{width: "300px",
                        height: "auto",
                        margin: "0 auto",
                        position: "relative"}}>
                        <LazyLoad width={300} height={300}>
                            <img src={image} style={{width:"300px", height:"300px",display: "block",
                                marginLeft: "auto",
                                marginRight: "auto"}} />
                        </LazyLoad>
                    </div>

                </div>
                <div style={{paddingLeft:"20px", paddingRight:"20px",
                    textAlign:"center", textTransform:"uppercase", fontFamily:"K2D"}}>
                    <div style={{marginTop:"15%", color:"white"}}>
                        {message1}
                    </div>
                    <div style={{color:"white", paddingTop:"5px"}}>
                        {message2}
                    </div>
                </div>

                <div style={{alignItems:"bottom", textAlign:"center",
                    paddingBottom:"15px",
                    position:"absolute",
                    bottom:0,
                    width: "100%"}}>
                    <div style={{color:"white", fontFamily:"K2D"}}>
                        GET IN TOUCH
                    </div>
                    <div style={{display:"flex", textAlign:"center", paddingTop:"20px", paddingBottom:"25px",
                        flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <div style={{paddingRight:"25px"}}>
                            <a href="mailto:vsrmanoj.99@gmail.com" target="/"><img src="/images/mail.png"/></a>
                        </div>
                        <div style={{paddingRight:"25px"}}>
                            <a href="https://linkedin.com/in/manoj-kumarv" target="/"><img src="/images/linkedin.png"/></a>
                        </div>
                        <div style={{paddingRight:"25px"}}>
                            <a href="https://www.instagram.com/_manoj_._kumar_/" target="/"><img src="/images/instagram.png"/></a>
                        </div>
                        <div>
                            <a href="https://github.com/shadowmanoj" target="/"><img src="/images/github.png"/></a>
                        </div>
                    </div>
                    <div style={{color:"white",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 400, fontFamily:"K2D"}}>
                        Copyright © 2024, Manoj kumar. All rights reserved.
                    </div>
                </div>
            </div>

        </>

    )
}
export default HomeMobile